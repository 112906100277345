.spinner_detalle{
    position: fixed;
    top: 50%;
    left: 50%;
    
}

.snippet{
    text-align: left; 
}

.desc{
    text-align: left; 
    white-space: pre-line;
    flex:1;
}

.recs{
    text-align: left; 
    white-space: pre-line;
    flex:1;
}

.bennies{
    text-align: left; 
    white-space: pre-line;
    flex:1;
}